import React, { Component } from 'react';
import List from '@material-ui/core/List';
import FaqCollapse from './faqCollaps'
import faqStyle from './faq.module.css'


class SBFaq extends Component {
    render() {
        return (
            <div className={faqStyle.container}>
                <h4 style={{letterSpacing: 1.5, fontSize: '32px', textAlign: 'center', paddingBottom: '32px'}}>FAQ</h4>
                <p style={{fontWeight: 300, textAlign: "center"}}>
                    Here are some of the most asked questions. If you still have questions or concerns, <a href="mailto:info@sonic-instruments.co?Subject=Question%20about%20MO-Band">shoot us an email</a> anytime and we'll get back to you in no time.
                </p>
                <List component="div" aria-labelledby="faq">
                    <FaqCollapse 
                        q="Can I use it on macOS? How about on Windows?" 
                        a="Absolutely! S-Board is compatible with both Mac and PC. (Apple Silicon compatible)"
                    />
                    <FaqCollapse 
                        q="Is S-Board compatible with Apple Silicon Macs?" 
                        a="Yes! S-Board is compatible with both Intel Mac and Apple Silicon Macs."
                    />
                    <FaqCollapse 
                        q="Can S-Board be used as a standalone application?" 
                        a="For sure! If you're on Windows platform, just make sure to select ASIO audio drivers when using the standalone, otherwise, the latency is going to be a problem."
                    />
                    <FaqCollapse 
                        q="How to activate S-Board and individual units? How to use the serial number?" 
                        a="After download and installation, please open the S-Board Standalone App, You would be prompted to log in at first launch, please do that. If you're a paying customer, S-Board and the modules you just bought will be activated automatically. If you are on trial or received an NFR license, you would need to activate it manually with the serial number from the email. Here's how to do it: click on 'Options' on the top-left corner, choose 'Activate by serial number' from the menu. Make sure you are using the standalone app (not the plugins), otherwise you won't find the 'Options' button. Also, make sure to copy and paste the entire serial number highlighted in yellow background."
                    />
                    <FaqCollapse 
                        q="Can I use S-Board in Ableton Live? How about other DAWs?" 
                        a="100%! S-Board is available in AudioUnit, VST and VST3. So it'll be happy to work with any DAW except Pro Tools. Ableton Live, Logic Pro, Garage Band, Reaper, Studio One, Cubase, FL Studio... You name it."
                    />
                    <FaqCollapse 
                        q="Can I use S-Board on multiple computers at the same time?" 
                        a="Of course. You can use S-Board on 2 computers simultaneously with one license. If you have more than 2 computers, well, you definitely would not care to buy another copy would you? :-)"
                    />
                    <FaqCollapse 
                        q="What’s the return policy?" 
                        a="Sorry, softwares are non-refundable. But don't worry we got your back. Contact us anytime if there's any problem, we’ll take care of it."
                    />
                </List>
            </div>
        );
    }
}

export default SBFaq