import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import faqStyle from './faq.module.css'

class FaqCollapse extends Component {
    constructor(props) {
        super(props)
        this.state = {open: false}
        this.handleClick = this.handleClick.bind(this)

    }
    handleClick() {
        this.setState({open: !this.state.open})
        if (window.fbq != null) {
            window.fbq('trackCustom', this.props.q);
        }
    }
    render() {
        return (
            <div >
                <ListItem button onClick={this.handleClick} style={{backgroundColor: '#f5f5f5'}}>
                    <ListItemText primary={`• ` + this.props.q} />
                    {this.state.open ? <ExpandLess /> : <ExpandMore /> }
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <ListItem div className={faqStyle.nested}>
                        <ListItemText>{this.props.a}</ListItemText>
                    </ListItem>
                </Collapse>
            </div>
        )
    }
}

export default FaqCollapse;