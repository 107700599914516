import React from 'react';
import ProductCard from './productCardShop';
import * as modules from '../../SBoard_Products'
import SBDStyle from './SBoardDescription.module.css'

class SBoardRelatedCards extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const currentItem = this.props.currentItem.toLowerCase()
        const [name, product] = Object.entries(modules).find(([n, item]) => item.name.toLowerCase() === currentItem)
        const bundleSave = Object.entries(modules).filter(([n, item]) => item.tag === "Bundle" && item.name !== currentItem).map(([n, item], i) => {
            return  (
                <div className={SBDStyle.relatedProductsItem} key={i}>
                    <ProductCard {...item}/>
                </div>
            )
        })
        const includedIn = product.includedIn.map((name, i) => {
            const [n, bundle] = Object.entries(modules).find(([n, item]) => item.licenseName === name)
            return (
                <div className={SBDStyle.relatedProductsItem} key={i}>
                    <ProductCard {...bundle}/>
                </div>
            )
        })
        const related = Object.entries(modules).filter(([n, item]) => item.tag !== "Bundle" && item.name.toLowerCase() !== currentItem).map(([n, item], i) => {
            return (
                <div className={SBDStyle.relatedProductsItem} key={i}>
                    <ProductCard {...item}/>
                </div>
            )
        })
        const bundle = (
            <div>
                <div className={SBDStyle.titleContainer}>
                    <h2>Bundle Save</h2>
                </div>
                <div className={SBDStyle.relatedProducts}>
                    {bundleSave}
                </div>
            </div>
        )
        const includedInBunlde = (
            <div>
                <div className={SBDStyle.titleContainer}>
                    <h2>Included in</h2>
                </div>
                <div className={SBDStyle.relatedProducts}>
                    {includedIn}
                </div>
            </div>
        )
        var includeObjs = []
        product.includes.map((name, i) => {
            const [n, p] = Object.entries(modules).find(([n, item]) => item.licenseName === name)
            if (p.tag === "Bundle")
            {
                p.includes.forEach((name) => {
                    const [n, flatP] = Object.entries(modules).find(([n, item]) => item.licenseName === name)
                    includeObjs.push(flatP)
                })
            } else {
                includeObjs.push(p);
            }
            
        })
        const includesProduct = (
            <div>
                <div className={SBDStyle.titleContainer}>
                    <h2>Includeds</h2>
                </div>
                <div className={SBDStyle.relatedProducts}>
                    {includeObjs.map((item, i)=> {
                        return (
                            <div className={SBDStyle.relatedProductsItem} key={i}>
                                <ProductCard {...item}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
        return (
            <div>
                {currentItem === "s-board" ? bundle : null}
                {(product.includedIn === undefined || product.includedIn == 0 || currentItem === "s-board") ? null : includedInBunlde}
                {product.tag === "Bundle" ? null :
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>{currentItem === "s-board" ? "More Modules" : "Related Products"}</h2>
                    </div>
                    <div className={SBDStyle.relatedProducts}>
                        {related}
                    </div>
                </div>
                }
                {product.tag === "Bundle" ? includesProduct : null}
            </div>
            
        )
    }
}

export default SBoardRelatedCards