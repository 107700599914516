import React from 'react';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SBDStyle from './SBoardDescription.module.css'
import DAWs from '../assets/daws.png'
import ReviewCard from './reviewCard'
import { navigate, navigateTo } from 'gatsby';

class SB_Footer extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Reviews</h2>
                        <ReviewCard 
                            title="Sonic Instruments S-Board plug-in: A patchable creative effects rack!"
                            subtitle="gearnews.com"
                            domain="https://www.gearnews.com/"
                            excerpt={"Virtual racks with piles upon piles of effects and audio processors are a hot commodity in 2020. Producing S-Board, developer Sonic Instruments is experimenting with the format, made popular by the likes of Slate Digital VMR and IK Multimedia MixBox. Instead of audio sweeteners, though, S-Board contains a bunch of creative effects that you can modulate with LFOs and virtual patch cables, Eurorack-style."}
                            img="https://www.gearnews.com/wp-content/uploads/2020/12/S-Board-plug-in.jpg"
                            key="review"
                            siteUrl="https://www.gearnews.com/sonic-instruments-s-board-plug-in-a-patchable-creative-effects-rack/"
                        />
                    </div>
                </div>
                <div style={{height: "80px"}}/>
                <div className={SBDStyle.content}>
                    <h4>Comprehensive compatability</h4>
                    <p>S-Motion is compatible with Windows 7 or newer, and macOS 10.13.6 or newer (Big Sur and Apple Silicon supported). It works great with all major DAWs such as Logic Pro, Ableton Live, Cubase/Nuendo, Pro Tools, GarageBand, FL Studio, Studio One, and Reaper.</p>
                    <div style={{marginTop: '1rem', display: 'flex'}}>
                        <FontAwesomeIcon icon={faWindows} size="2x" style={{marginRight: '32px'}}/>
                        <FontAwesomeIcon icon={faApple} size="2x" style={{marginRight: '8px'}}/>
                    </div>
                </div>
                <div className={SBDStyle.contentContainer}>
                    <div className={SBDStyle.dawIcons}>
                        <img src={DAWs} width="80%"/>
                    </div>
                </div>
                <div style={{height: "16px"}}/>
                <div className={SBDStyle.content}>
                    <div style={{padding: '1rem', paddingLeft: 0}}>
                        <div>
                            <h4>15-day free trial</h4>
                            <p>Want to try before you buy? No problem. Click here for a 15-day unlimited trial license. </p>
                        </div>
                        <div style={{marginTop: '1rem'}}>
                            <button onClick={this.props.getTrial} className={SBDStyle.trialButton}>Try now</button>
                        </div>
                        <div>
                        <a href="https://sonic-instruments.co/s-board-manual" target="_blank"><button className={SBDStyle.manualButton}>User Manual</button></a>
                        </div>
                    </div>
                </div>
                <div style={{height: "60px"}}/>
            </div>
        )
    }
}

export default SB_Footer