import React from "react";
import ReviewCardStyle from './reviewCard.module.css'
import Share from './share'

function ReviewCard(props) {
    return (
        <div className={ReviewCardStyle.container}>
            <a href={props.siteUrl} target="_blank">
                <div className={ReviewCardStyle.cover} style={{backgroundImage: `url(${props.img})`}}>
                    <h3 className={ReviewCardStyle.title}>{props.title}</h3>
                    <div className={ReviewCardStyle.faviconContainer}><div className={ReviewCardStyle.favicon} style={{backgroundImage: `url(${props.domain}/favicon.ico)`}}></div></div>
                    <h4 className={ReviewCardStyle.subtitle}>{props.subtitle}</h4>
                </div>
            <div className={ReviewCardStyle.contentContainer}>
                <p className={ReviewCardStyle.excerpt}>{`${props.excerpt.substring(0, 220)}...`}</p>
                <div className={ReviewCardStyle.shareButtons}>
                    <Share 
                        socialConfig= {{
                            twitterHandle: '@sonic_inst',
                            config: {
                            url: `${props.siteUrl}`,
                            title: props.title,
                            },
                        }}
                        subtitle={props.subtitle}
                        tags={['MO-Band', 'SonicInstruments', 'MusicTech']}
                    />
                </div>
            </div>
            </a>
        </div>
    )
}

export default ReviewCard;