import DieRoboter from './src/assets/dieRoboter_panel.png';
import Bokeh from './src/assets/Bokeh_panel.png';
import SBDLX from './src/assets/sb_dlx.png';
import SBMODPACK from './src/assets/sb_mod_pack.png'
import ADSR from './src/assets/Adsr_panel.png';
import Regulator from './src/assets/Regulator_panel.png';
import Dispatcher from './src/assets/Dispatcher_panel.png';
import CVWriter from './src/assets/CVWriter_panel.png';
import sb_thumbnail from './src/assets/sb_std.png';

export const SBoardObj = 
{
    name: "S-Board",
    includesSoftware : true,
    licenseName: "SB_STD",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner:"8 stock modules included, endless sound shapping possibilities",
    tag: "Effect",
    price: 79,
    quantity: 1,
    currency:"$",
    description:"Modular effect board",
    url: "/s-board",
    img: sb_thumbnail,
    includedIn: ["SB_DLX"],
    includes: [],
    thumbnail: sb_thumbnail,
}

export const SBoardDLXObj = 
{
    name: "S-Board Deluxe",
    includesSoftware : true,
    licenseName: "SB_DLX",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner:"8 stock modules + modifier pack + dieRoboter + Bokeh",
    price: 158,
    quantity: 1,
    currency:"$",
    tag: "Bundle",
    flag: "40% off",
    description:"14 modules included",
    url: "/s-board/deluxe",
    img: SBDLX,
    includedIn: [],
    includes: ["SB_STD", "BOKEH", "DIEROBOTER", "SB_MOD_PACK"],
    thumbnail: SBDLX
}

export const SBModPackObj = 
{
    name: "S-Board Modifier Pack",
    includesSoftware : true,
    licenseName: "SB_MOD_PACK",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner:"ADSR + Regulator + Dispatcher + CVWriter",
    price: 39,
    quantity: 1,
    currency:"$",
    tag: "Bundle",
    flag: "40% off",
    description:"4 modules included",
    url: "/s-board/mod_pack",
    img: SBMODPACK,
    includedIn: ["SB_DLX"],
    includes: ["ADSR", "REGULATOR", "CVWRITER", "DISPATCHER"],
    thumbnail: SBMODPACK
}


export const DieRoboterObj = 
{
    name: "dieRoboter",
    img: DieRoboter,
    includesSoftware : true,
    licenseName: "DIEROBOTER",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner: "Analog 32-band vocoder",
    price: 59,
    currency: "$",
    description: "Classic robotic voice",
    tag: "Processor",
    url: "/s-board/dieRoboter",
    needsShadow: true,
    includedIn: ["SB_DLX"],
    includes: [],
    thumbnail: DieRoboter,
}

export const BokehObj = 
{
    name: "Bokeh",
    img: Bokeh,
    includesSoftware : true,
    licenseName: "BOKEH",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner: "Bidirectional reverb module",
    price: 59,
    currency: "$",
    description: "Space & vibe creator",
    tag: "Processor",
    url: "/s-board/bokeh",
    needsShadow: true,
    includedIn: ["SB_DLX"],
    includes: [],
    thumbnail: Bokeh,
}


export const ADSRObj = 
{
    name: "Adsr",
    img: ADSR,
    includesSoftware : true,
    licenseName: "ADSR",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner: "ADSR env generator module",
    price: 19,
    currency: "$",
    description: "Fine-tune your envelope",
    tag: "Modifier",
    url: "/s-board/adsr",
    needsShadow: true,
    includedIn: ["SB_DLX", "SB_MOD_PACK"],
    includes: [],
    thumbnail: ADSR,
}

export const RegulatorObj  = 
{
    name: "Regulator",
    img: Regulator,
    includesSoftware : true,
    licenseName: "REGULATOR",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner: "CV processing utility",
    price: 19,
    currency: "$",
    description: "100% control on your CV",
    tag: "Modifier",
    url: "/s-board/regulator",
    needsShadow: true,
    includedIn: ["SB_DLX", "SB_MOD_PACK"],
    includes: [],
    thumbnail: ADSR,
}

export const CVWriterObj = 
{
    name: "CVWriter",
    img: CVWriter,
    includesSoftware : true,
    licenseName: "CVWRITER",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner: "CV to automation module",
    price: 19,
    currency: "$",
    description: "Write CV into your DAW",
    tag: "Modifier",
    url: "/s-board/cvwriter",
    needsShadow: true,
    includedIn: ["SB_DLX", "SB_MOD_PACK"],
    includes: [],
    thumbnail: CVWriter,
}

export const DispatcherObj = 
{
    name: "Dispatcher",
    img: Dispatcher,
    includesSoftware : true,
    licenseName: "DISPATCHER",
    licenseType: "Perpetual",
    expiresAt: "null",
    oneliner: "CV dispatching module",
    price: 9,
    currency: "$",
    description: "Send a cv to 4 destinations",
    tag: "Modifier",
    url: "/s-board/dispatcher",
    needsShadow: true,
    includedIn: ["SB_DLX", "SB_MOD_PACK"],
    includes: [],
    thumbnail: Dispatcher,
}
