import React, { Component } from "react";
import PCSStyle from './productCardShop.module.css';
import { connect } from 'react-redux';
import { add_to_cart } from '../redux/cart.redux';
import { navigate } from "gatsby"
import { Link } from "gatsby";

class ProductShopCard extends Component {
    constructor(props) {
        super(props);
        this.addToCart = this.addToCart.bind(this);
        this.state = {hovered : false}
    }

    addToCart() {
        this.props.add_to_cart({
            ...this.props, 
            description: `${this.props.tag ? this.props.tag + ", " : ""}${this.props.description}.`,
            quantity: 1,
        });
        navigate('/cart');
    }

    render() {
        const name = this.props.name;
        const oneliner = this.props.oneliner;
        const price = this.props.price;
        const currency = this.props.currency;
        const img = this.props.img;
        const tag = this.props.tag;
        const description = this.props.description;
        const flag = this.props.flag;
        const url = this.props.url;
        const needsShadow = this.props.needsShadow;
        const {hovered} = this.state;
        return(
            <div className={PCSStyle.container}>
                <div className={PCSStyle.content}>
                    {flag ? 
                    <div className={PCSStyle.flag}>
                        {flag}
                    </div>
                    : null
                    }
                    <Link to={url} onMouseEnter={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })}>
                        <div className={PCSStyle.imgContainer}>
                            <img src={img} className={PCSStyle.coverImg} style={needsShadow ? {boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.5)'} : null}/>
                        </div>
                        <div className={PCSStyle.textArea}>
                            <div className={PCSStyle.tag}>{tag}</div>
                            <h3 style={{borderBottom: hovered ? "2px solid deepskyblue" : "2px solid transparent"}}>
                                {name}
                            </h3>
                            <p style={{fontSize:'14px', borderBottom: hovered ? "2px solid deepskyblue" : "2px solid transparent"}}>
                                {description}
                            </p>
                            <h4>{oneliner}</h4>
                        </div>
                    </Link>
                    <div className={PCSStyle.buttonArea}>
                        <div style={{display: 'block', width: '100%', position: 'relative'}}>
                            <div className={PCSStyle.priceTag}>
                                <p>{`${currency}${price}`}</p>
                            </div>
                            <button onClick={this.addToCart} className={PCSStyle.addToCartButton}>Add to cart</button>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

const actionCreators = { add_to_cart }

export default connect(null, actionCreators)(ProductShopCard)