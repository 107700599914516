import React from 'react';
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import SB_Footer from './SB_Description_Footer'
import Cover from '../assets/Adsr_panel.png'
import SBoardRelatedCards from "./SBoardRelatedCards";

class SBADSRDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>ADSR</h2>
                        <h3>Fine-tune the envelope of your sound | Made for S-Board</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner} style={{maxHeight: '372px', width: 'auto'}}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>ADSR envelope generator</p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Modifier</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>ADSR</p>
                                <div className={SBDStyle.currPrice}>US $19</div>
                            </div>          
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>Must use with S-Board!</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Overview</h2>
                    </div>
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Trigger it with any modifiers</h4>
                        <p>Any modifiers can be used to trigger the ADSR envelope. Simply plug the modifier module in, adjust the triggering threshold, the envelope will be triggered every time the input is above the threshold.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Fine tune the curve of the envelope</h4>
                        <p>Want further controls on the envelope beside the ordinary attack, decay, sustain, and release? Use the curve knob to shape the curvature of the envelope. You'll instantly hear and see the difference.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Intuitive visualization</h4>
                        <p>The waveform monitor gives you an intuitive visualization, makes it easy for you to see what's going on, and how to tweak the parameters.</p>
                    </div>
                </div>
                <SBoardRelatedCards currentItem="adsr"/>

                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBADSRDescription